(function($){

	/* Get accurate Viewport sizing (no jQuery) */
    var viewportwidth;
    var viewportheight;

    function getWindowSize(){
        if (typeof window.innerWidth != 'undefined'){
            viewportwidth = window.innerWidth,
            viewportheight = window.innerHeight
        }else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0){
            viewportwidth = document.documentElement.clientWidth,
            viewportheight = document.documentElement.clientHeight
        }else{
            viewportwidth = document.getElementsByTagName('body')[0].clientWidth,
            viewportheight = document.getElementsByTagName('body')[0].clientHeight
        }
    }

    
    function initSlider() {
        $('.slider').slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            adaptiveHeight: true,
        });
    }


    /*
     *
     * Equal Height jQuery Snippet.  Using this for footer and grid accordion
     *
     */
    var equalheight = function (container){

        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0,
            currentDiv;
    
        $(container).each(function () {

            $el = $(this);
            $el.height('auto');
            topPosition = $el.position().top;

            if (currentRowStart < topPosition - 1 || currentRowStart > topPosition + 1) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs = [];
                currentRowStart = topPosition;
                currentTallest = $el.height();
                rowDivs.push($el);
            }
            else {
                rowDivs.push($el);
                currentTallest = Math.max(currentTallest, $el.height());
            }
        });
        
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].css('height', currentTallest);
        }
    };
    

	/* Document Ready */
	$(document).ready(function() {
		getWindowSize();
        equalheight('.vertical_center');

        initSlider();


        //Sidr
        $('#mobile_nav_btn').sidr({
            name: 'sidr',
            side: 'right',
            source : function() {
                return '<div id="mobile-menu"><span class="sidr__close">Close Menu &times;</span><div class="sidr__inner">' + $('#main_nav').html() + '</div></div>';
            }
        });
        $('.sidr__close').click(function() {
            $.sidr('close','sidr');
        });

	});

	$(window).on("resize", function() {
		getWindowSize();
        equalheight('.vertical_center');
	});

})(jQuery);